import SignUpService from "@/core/services/signup.service";

// action types
export const SEND_INVITATION = "sendInvitation";
export const SEND_INVITATIONS = "sendInvitations";
export const GET_INVITATIONS = "getAllInvitations";

// mutation types
export const INVITATION_SENT = "invitationSent";
export const INVITATIONS_SENT = "invitationsSent";
export const SET_INVITATIONS = "setInvitations";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  message: "",
  status: "",
  invitations: []
};

const getters = {
  // getMessage(state) {
  //     return state.message;
  // },
  // getError(state) {
  //     return state.errors;
  // }
};

const actions = {
  [SEND_INVITATION](context, payload) {
    return new Promise((resolve, reject) => {
      SignUpService.sendInvite(payload)
        .then(response => {
          context.commit(INVITATION_SENT, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [SEND_INVITATIONS](context, payload) {
    return new Promise((resolve, reject) => {
      SignUpService.sendInvites(payload)
        .then(response => {
          context.commit(INVITATIONS_SENT, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GET_INVITATIONS](context) {
    return new Promise((resolve, reject) => {
      SignUpService.all()
        .then(response => {
          context.commit(SET_INVITATIONS, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [INVITATION_SENT](state, data) {
    state.message = data.message;
    state.status = "";
    state.errors = null;
  },
  [SET_INVITATIONS](state, data) {
    state.invitations = data;
    state.errors = null;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
