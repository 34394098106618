import axios from "axios";

/**
 * Service to call authentication endpoints
 */
const AuthenticationService = {
  /**
   * Login a user
   * @param credentials
   * @returns {*}
   */
  login(credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/login", credentials)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * forgot password
   * @param credentials
   * @returns {*}
   */
  forgotPassword(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/forgot-password", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * forgot password
   * @param credentials
   * @returns {*}
   */
  resetPassword(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/reset-password", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Register a new user
   * @param params
   * @returns {*}
   */
  register(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/signup", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Register a new user
   * @param params
   * @returns {*}
   */
  resendConfirmation(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/resend-email-confirmation", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * COnfirm a new user
   * @param params
   * @returns {*}
   */
  confirmation(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("account/confirm-signup", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default AuthenticationService;
