"use strict";

var KTLayoutExamples = function() {

    var initDefaultMode = function(element) {
        var elements = element;
        if (typeof elements === 'undefined') {
            elements = document.querySelectorAll('.example:not(.example-compact):not(.example-hover):not(.example-basic)');
        }

        if (elements && elements.length > 0 ) {
            for (var i = 0; i < elements.length; ++i) {
                var example = elements[i];
                var copy = Sourcery.find(example, '.example-copy');

                if (copy) {
                    var clipboard = new ClipboardJS(copy, {
                        target: function(trigger) {
                            var example = trigger.closest('.example');
                            var el = Sourcery.find(example, '.example-code .tab-pane.active');

                            if (!el) {
                                el = Sourcery.find(example, '.example-code');
                            }

                            return el;
                        }
                    });

                    clipboard.on('success', function(e) {
                        Sourcery.addClass(e.trigger, 'example-copied');
                        e.clearSelection();

                        setTimeout(function() {
                            Sourcery.removeClass(e.trigger, 'example-copied');
                        }, 2000);
                    });
                }
            }
        }
    }

    var initCompactMode = function(element) {
        var example,code,toggle,copy, clipboard;

        var elements = element;
        if (typeof elements === 'undefined') {
            var elements = document.querySelectorAll('.example.example-compact');
        }

        if (elements && elements.length > 0 ) {
            for (var i = 0; i < elements.length; ++i) {
                var example = elements[i];
                var toggle = Sourcery.find(example, '.example-toggle');
                var copy = Sourcery.find(example, '.example-copy');

                // Handle toggle
                Sourcery.addEvent(toggle, 'click', function() {
                    var example = this.closest('.example');
                    var code =  Sourcery.find(example, '.example-code');
                    var the = this;

                    if (Sourcery.hasClass(this, 'example-toggled')) {
                        Sourcery.slideUp(code, 300, function() {
                            Sourcery.removeClass(the, 'example-toggled');
                            Sourcery.removeClass(code, 'example-code-on');
                            Sourcery.hide(code);
                        });
                    } else {
                        Sourcery.addClass(code, 'example-code-on');
                        Sourcery.addClass(this, 'example-toggled');
                        Sourcery.slideDown(code, 300, function() {
                            Sourcery.show(code);
                        });
                    }
                });

                // Handle copy
                if (copy) {
                    var clipboard = new ClipboardJS(copy, {
                        target: function(trigger) {
                            var example = trigger.closest('.example');
                            var el = Sourcery.find(example, '.example-code .tab-pane.active');

                            if (!el) {
                                el = Sourcery.find(example, '.example-code');
                            }

                            return el;
                        }
                    });

                    clipboard.on('success', function(e) {
                        Sourcery.addClass(e.trigger, 'example-copied');
                        e.clearSelection();

                        setTimeout(function() {
                            Sourcery.removeClass(e.trigger, 'example-copied');
                        }, 2000);
                    });
                }
            }
        }
    }

    return {
        init: function(element, options) {
            initDefaultMode(element);
            initCompactMode(element);
        }
    };
}();



export default KTLayoutExamples;