import axios from "axios";

const RoleService = {
  /**
   * Gets a role
   * @param  {} id
   */
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`roles/${id}`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all roles
   * @param  {} slug
   */
  all() {
    return new Promise((resolve, reject) => {
      axios
        .get(`roles`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default RoleService;
