import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import auth from "./auth.module";
import invite from "./signup.module";
import roles from "./role.module";
import actionParty from "./actionparty.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    roles,
    invite,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    actionParty
  },
  plugins: [createPersistedState()]
});
