import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import TokenService from "@/core/services/token.service";
// import store from "@/core/services/store";
// import router from "@/router";
// import { LOGOUT } from "@/core/services/store/auth.module";
/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = "http://core.acclio-staging.com/api/";
    // Vue.axios.defaults.baseURL = "http://127.0.0.1:8000/api/";

    // axios.interceptors.response.use(undefined, function(error) {
    //   if (error) {
    //     const originalRequest = error.config;
    //     if (error.response.status === 401 && !originalRequest._retry) {
    //       originalRequest._retry = true;
    //       store.dispatch(LOGOUT);
    //       return router.push("/login");
    //     }
    //   }
    // });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${
      TokenService.getToken().accessToken
    }`;
  },

  async sendRequest(httpMethod, url, data, additionalHeaders, responseType) {
    // let token = TokenService.getToken().accessToken !== null ?
    //               TokenService.getToken().accessToken : "";
    // const url ='http://core.acclio-staging.com/api/';

    let headers = {
      "content-type": "application/json"
    };

    // if (token !== '') {
    //   Object.assign(headers, { Authorization: `Bearer ${token}` });
    // }

    for (const headerKey in additionalHeaders) {
      headers[headerKey] = additionalHeaders[headerKey];
    }

    return new Promise((resolve, reject) => {
      axios
        .request({
          method: httpMethod,
          url,
          data,
          headers: headers,
          responseType: responseType || "json"
        })
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
