import axios from "axios";
import TokenService from "@/core/services/token.service";

const CoreService = {
  init() {
    //axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
    axios.defaults.baseURL = "https://www.pilothse.com/api/";
    axios.defaults.headers.common.Accept = "application/json";

    axios.interceptors.request.use(
      config => {
        let token = TokenService.getToken()
          ? TokenService.getToken().accessToken
          : null;
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        console.log("Interceptor Request: ", config);
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        console.log("Interceptor Response: ", response);
        return response;
      },
      error => {
        if (error.status == 401) {
          this.$router.push({ name: "login" });
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      TokenService.getToken().accessToken
    }`;
  }
};
export default CoreService;
