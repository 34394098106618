import ActionPartyService from "@/core/services/actionparty.service";

// action types
export const CREATE_ACTION_PARTY = "createActionParty";
export const GET_ACTION_PARTIES = "getActionParties";
export const GET_ACTION_PARTY = "getActionParty";
export const DELETE_ACTION_PARTY = "deleteActionParty";
export const UPDATE_ACTION_PARTY = "updateActionParty";

// mutation types
export const SET_ACTION_PARTIES = "setActionParties";
export const SET_ACTION_PARTY = "setActionParty";
export const ACTION_PARTY_CREATED = "actionPartyCreated";
export const ACTION_PARTY_UPDATED = "actionPartyUpdated";
export const ACTION_PARTY_DELETED = "actionPartyDeleted";
export const SET_ERROR = "setError";

const state = {
  actionParties: [],
  actionParty: null,
  error: null,
  message: ""
};

const getters = {
  getMessage(state) {
    return state.message;
  },
  getError(state) {
    return state.error;
  },
  getActionParties(state) {
    return state.actionParties;
  },
  getActionParty(state) {
    return state.actionParty;
  }
};

const actions = {
  [CREATE_ACTION_PARTY](context, payload) {
    return new Promise((resolve, reject) => {
      ActionPartyService.create(payload)
        .then(response => {
          context.commit(ACTION_PARTY_CREATED, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GET_ACTION_PARTY](context, id) {
    return new Promise((resolve, reject) => {
      ActionPartyService.get(id)
        .then(response => {
          context.commit(SET_ACTION_PARTY, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [GET_ACTION_PARTIES](context, slug) {
    return new Promise((resolve, reject) => {
      ActionPartyService.all(slug)
        .then(response => {
          context.commit(SET_ACTION_PARTIES, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [UPDATE_ACTION_PARTY](context, id, payload) {
    return new Promise((resolve, reject) => {
      ActionPartyService.update(id, payload)
        .then(response => {
          context.commit(ACTION_PARTY_UPDATED, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [DELETE_ACTION_PARTY](context, id) {
    return new Promise((resolve, reject) => {
      ActionPartyService.delete(id)
        .then(response => {
          context.commit(ACTION_PARTY_DELETED, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [ACTION_PARTY_CREATED](state, data) {
    state.message = data.message;
    state.error = null;
  },
  [SET_ACTION_PARTY](state, data) {
    state.actionParty = data;
    state.error = null;
  },
  [SET_ACTION_PARTIES](state, data) {
    state.actionParties = data;
    state.error = null;
  },
  [SET_ERROR](state, error) {
    state.error = error;
    state.message = "";
  },
  [ACTION_PARTY_UPDATED](state, data) {
    state.message = data.message;
    state.error = null;
  },
  [ACTION_PARTY_DELETED](state, data) {
    state.message = data.message;
    state.error = null;
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
