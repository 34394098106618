import ApiService from "@/core/services/api.service";
import CoreService from "@/core/services/core.service";
import TokenService from "@/core/services/token.service";
import AuthenticationService from "@/core/services/authentication.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "LogIn";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const RESEND_CONFIRMATION = "resendConfirmationMail";
export const CONFIRMATION = "confirmUser";
export const UPDATE_AUTH = "updateAuth";
export const UPDATE_COMPANY = "updateCompany";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_COMPANY = "setCompany";
export const SET_REGISTRATION = "setRegistration";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  token: {},
  isAuthenticated: !!TokenService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentToken(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  hasPermission: state => permission => {
    var permissions = permission.split(",");
    return permissions.includes(state.user.role.name);
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      AuthenticationService.login(credentials)
        .then(response => {
          console.log(response);
          context.commit(SET_AUTH, response.data);
          resolve(response);
        })
        .catch(e => {
          reject(e);
          console.log(e);
          if (e.status && e.status == 303) {
            context.commit(SET_AUTH, e.data);
          }
          if (e.status && e.status == 307) {
            context.commit(SET_AUTH, e.data);
          }
          context.commit(SET_ERROR, e);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  [REGISTER](context, params) {
    return new Promise((resolve, reject) => {
      AuthenticationService.register(params)
        .then(response => {
          console.log(response);
          context.commit(SET_AUTH, response.data);
          resolve(response);
        })
        .catch(e => {
          reject(e);
          console.log(e);
          context.commit(SET_ERROR, e);
        });
    });
  },

  [RESEND_CONFIRMATION](context, params) {
    return new Promise((resolve, reject) => {
      AuthenticationService.resendConfirmation(params)
        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
          context.commit(SET_ERROR, e);
        });
    });
  },

  [CONFIRMATION](context, params) {
    return new Promise((resolve, reject) => {
      AuthenticationService.confirmation(params)
        .then(response => {
          resolve(response);
          context.commit(SET_AUTH, response.data);
        })
        .catch(e => {
          reject(e);
          context.commit(SET_ERROR, e);
        });
    });
  },

  [VERIFY_AUTH](context) {
    if (TokenService.getToken()) {
      CoreService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },

  [UPDATE_AUTH](context, data) {
    context.commit(SET_USER, data);
  },

  [UPDATE_COMPANY](context, data) {
    context.commit(SET_COMPANY, data);
  },

  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.user = data.user;
    state.token = {
      accessToken: data.access_token,
      tokenType: data.token_type,
      expiresAt: data.expires_at
    };
    TokenService.saveToken(state.token);
    state.errors = {};
  },

  [SET_USER](state, data) {
    state.user = data.user;
    state.errors = {};
  },

  [SET_COMPANY](state, data) {
    state.user.company = data;
    state.errors = {};
  },

  [SET_REGISTRATION](state, data) {
    state.user = data.user;
    state.errors = {};
  },

  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.token = {};
    state.errors = {};
    TokenService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
