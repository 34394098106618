import axios from "axios";

const SignUpService = {
  /**
   * Invite a multiple users
   * @param  params
   * @returns {*}
   */
  sendMultipleInvite(admins, editors, contributors) {
    const adminsRequest = axios.post("users-invitation", {
      emails: admins,
      role_id: 1
    });
    const editorsRequest = axios.post("users-invitation", {
      emails: editors,
      role_id: 2
    });
    const contributorsRequest = axios.post("users-invitation", {
      emails: contributors,
      role_id: 3
    });

    var validParams = [];

    if (admins.length > 0) {
      validParams.push(adminsRequest);
    }

    if (editors.length > 0) {
      validParams.push(editorsRequest);
    }

    if (contributors.length > 0) {
      validParams.push(contributorsRequest);
    }

    return new Promise((resolve, reject) => {
      axios
        .all(validParams)
        .then(
          axios.spread((...results) => {
            resolve(results);
          })
        )
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Invite a new user
   * @param  params
   * @returns {*}
   */
  sendInvite(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("signup-invitations", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Send multiple invites to users with one role
   *
   * @param {*} params
   * @returns
   */
  sendInvites(params) {
    return new Promise((resolve, reject) => {
      axios
        .post("users-invitation", params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  resendInvitation(id, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`signup-invitations/${id}/resend`, params)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get all invitations
   */
  all() {
    return new Promise((resolve, reject) => {
      axios
        .get("signup-invitations")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default SignUpService;
