import RoleService from "@/core/services/role.service";

// action types
export const GET_ROLES = "getRoles";

// mutation types
export const SET_ROLES = "setRoles";
export const SET_ERROR = "setError";

const state = {
  roles: [],
  errors: null,
  message: "",
  status: ""
};

const getters = {
  // getMessage(state) {
  //     return state.message;
  // },
  // getError(state) {
  //     return state.errors;
  // },
  getRoles(state) {
    return state.roles;
  }
};

const actions = {
  [GET_ROLES](context) {
    return new Promise((resolve, reject) => {
      RoleService.all()
        .then(response => {
          context.commit(SET_ROLES, response.data);
          resolve(response);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ROLES](state, data) {
    state.roles = data;
    state.status = "";
    state.errors = null;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};
