import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/layout/layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/dashboard.vue")
        },
        {
          path: "/observations/create",
          name: "create-observations",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/observations/create.vue")
        },
        {
          path: "/observations",
          name: "observations",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/observations/view.vue")
        },
        {
          path: "/observations/assigned",
          name: "assigned-observations",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/observations/assigned.vue")
        },
        {
          path: "/observations/edit/:id",
          name: "edit-observations",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/observations/edit.vue")
        },
        {
          path: "/observations/details/:id",
          name: "observation-details",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/observations/details.vue")
        },
        {
          path: "/energy-logging/create",
          name: "create-energy-log",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/energy-logging/create.vue")
        },
        {
          path: "/energy-logging",
          name: "energy-logging",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/energy-logging/view.vue")
        },
        {
          path: "/energy-logging/edit/:id",
          name: "edit-energy-log",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/energy-logging/edit.vue")
        },
        {
          path: "/waste-logging/create",
          name: "create-waste-log",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/waste-logging/create.vue")
        },
        {
          path: "/waste-logging",
          name: "waste-logging",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/waste-logging/view.vue")
        },
        {
          path: "/waste-logging/edit/:id",
          name: "edit-waste-log",
          meta: {
            roles: ["*"]
          },
          component: () => import("@/view/pages/waste-logging/edit.vue")
        },
        {
          path: "/users",
          name: "users-list",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/users/View.vue")
        },
        {
          path: "/users/details/:id",
          name: "users-details",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/users/Details.vue")
        },
        {
          path: "/users/invites",
          name: "users",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/users/Invites.vue")
        },
        {
          path: "/users/invite",
          name: "invite-users",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/users/Create.vue")
        },
        {
          path: "user/profile",
          name: "user-profile",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/user-profile/profile.vue")
        },
        {
          path: "/action-parties/create",
          name: "create-action-parties",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/action-parties/Create.vue")
        },
        {
          path: "/action-parties",
          name: "action-parties",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/action-parties/View.vue")
        },
        {
          path: "/action-parties/edit/:id",
          name: "edit-action-parties",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/action-parties/Edit.vue")
        },
        {
          path: "/observation-categories/create",
          name: "create-observation-categories",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/observation-categories/Create.vue")
        },
        {
          path: "/observation-categories",
          name: "observation-categories",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/observation-categories/View.vue")
        },
        // {
        //   path: "/observation-types/create",
        //   name: "create-observation-types",
        //   meta: {
        //     roles: ["Admin"]
        //   },
        //   component: () => import("@/view/pages/observation-types/Create.vue")
        // },
        // {
        //   path: "/observation-types",
        //   name: "observation-types",
        //   meta: {
        //     roles: ["Admin"]
        //   },
        //   component: () => import("@/view/pages/observation-types/View.vue")
        // },
        {
          path: "/energy-streams",
          name: "energy-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/energy-streams/View.vue")
        },
        {
          path: "/energy-streams/edit/:id",
          name: "edit-energy-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/energy-streams/Edit.vue")
        },
        {
          path: "/waste-streams/edit/:id",
          name: "edit-waste-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/waste-streams/Edit.vue")
        },
        {
          path: "/observation-categories/edit/:id",
          name: "edit-observation-categories",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/observation-categories/Edit.vue")
        },
        {
          path: "/observation-types/edit/:id",
          name: "edit-observation-types",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/observation-types/Edit.vue")
        },
        {
          path: "/locations/edit/:id",
          name: "location-edit",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/locations/Edit.vue")
        },
        {
          path: "/energy-streams/create",
          name: "create-energy-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/energy-streams/Create.vue")
        },
        {
          path: "/waste-streams",
          name: "waste-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/waste-streams/View.vue")
        },
        {
          path: "/waste-streams/create",
          name: "create-waste-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/waste-streams/Create.vue")
        },
        {
          path: "/locations/create",
          name: "create-locations",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/locations/Create.vue")
        },
        {
          path: "/locations",
          name: "locations",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/locations/View.vue")
        },
        {
          path: "/company-profile",
          name: "company-profile",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/company-profile/profile.vue")
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/login")
    },
    {
      path: "/register",
      name: "register",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/register")
    },
    {
      path: "/invitee-signup",
      name: "invitee-signup",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/invitee-signup")
    },
    {
      path: "/registration-complete",
      name: "registration-complete",
      meta: {
        roles: ["*"]
      },
      component: () =>
        import("@/view/pages/authentication/registration-complete")
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/forgot-password")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/reset-password")
    },
    {
      path: "/email-confirmation",
      name: "email-confirmation",
      meta: {
        roles: ["*"]
      },
      component: () => import("@/view/pages/authentication/email-confirmation")
    },
    {
      path: "/",
      redirect: "/onboarding",
      meta: {
        roles: ["admin"]
      },
      component: () => import("@/view/layout/onboarding"),
      children: [
        {
          path: "/onboarding/welcome",
          name: "onboarding-welcome",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/welcome.vue")
        },
        {
          path: "/onboarding/company",
          name: "onboarding-company",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/company.vue")
        },
        {
          path: "/onboarding/company-setup",
          name: "onboarding-company-setup",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/company-setup.vue")
        },
        {
          path: "/onboarding/choose-setup",
          name: "onboarding-choose-setup",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/choose-setup.vue")
        },
        {
          path: "/onboarding/invite-admin",
          name: "onboarding-invite-admin",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/invite-admin.vue")
        },
        {
          path: "/onboarding/invitation-sent",
          name: "onboarding-invitation-sent",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/invitation-sent.vue")
        },
        {
          path: "/onboarding/company-locations",
          name: "onboarding-company-locations",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/onboarding/company-locations.vue")
        },
        {
          path: "/onboarding/energy-streams",
          name: "onboarding-energy-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/energy-streams.vue")
        },
        {
          path: "/onboarding/waste-streams",
          name: "onboarding-waste-streams",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/waste-streams.vue")
        },
        {
          path: "/onboarding/observation-classes",
          name: "onboarding-observation-classes",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/onboarding/observation-classes.vue")
        },
        {
          path: "/onboarding/observation-types",
          name: "onboarding-observation-types",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/onboarding/observation-types.vue")
        },
        {
          path: "/onboarding/choose-action-party",
          name: "onboarding-choose-action-party",
          meta: {
            roles: ["Admin"]
          },
          component: () =>
            import("@/view/pages/onboarding/choose-action-party.vue")
        },
        {
          path: "/onboarding/action-parties",
          name: "onboarding-action-parties",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/action-parties.vue")
        },
        {
          path: "/onboarding/invite-users",
          name: "onboarding-invite-users",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/invite-users.vue")
        },
        {
          path: "/onboarding/complete",
          name: "onboarding-complete",
          meta: {
            roles: ["Admin"]
          },
          component: () => import("@/view/pages/onboarding/complete.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
